import React  ,{ useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const handleLogin = () => {
    // Redirect to Google on login button click
    window.location.href = 'https://example-sso.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=58f4r4470itjvrvi6l9mvne8sf&redirect_uri=https://accounts.webexpertsagency.com/home&scope=openid';
  };

  return (
    <div>
      <h1>Login</h1>
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};


const Home = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const getAuthorizationCode = () => {
      const searchParams = new URLSearchParams(window.location.search);
      return searchParams.get('code');
    };

    const redirectToGoogle = () => {
      window.location.href = 'https://example-sso.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=58f4r4470itjvrvi6l9mvne8sf&redirect_uri=https://accounts.webexpertsagency.com/home&scope=openid';
    };

    const exchangeCodeForTokens = async (code) => {
      try {
        const tokenEndpoint = 'https://example-sso.auth.us-east-1.amazoncognito.com/oauth2/token';
        const clientId = '58f4r4470itjvrvi6l9mvne8sf';
        const redirectUri = 'https://accounts.webexpertsagency.com/home';

        const response = await axios.post(
          tokenEndpoint,
          {
            grant_type: 'authorization_code',
            client_id: clientId,
            code: code,
            redirect_uri: redirectUri,
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );

        const idToken = response.data.id_token;
        const decodedIdToken = parseJwt(idToken);
        setUserData({
          email: decodedIdToken.email,
          username: decodedIdToken.username,
        });
      } catch (error) {
        console.error('Error exchanging code for tokens:', error);
      }
    };

    const parseJwt = (token) => {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    };

    const authorizationCode = getAuthorizationCode();

    if (authorizationCode) {
      exchangeCodeForTokens(authorizationCode);
    } else {
      // Redirect to google.com if code is not present
      redirectToGoogle();
    }
  }, []); 

  return (
    <div>
      <h1>Hello from Dispatch!</h1>
      {userData && (
        <div>
          <p>Email: {userData.email}</p>
        </div>
      )}
    </div>
  );
};


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home />} />
      </Routes>
    </Router>
  );
};

export default App;
